<script lang="ts" setup>
import { computed, ref } from 'vue'
import { cva } from 'class-variance-authority'
import { useElementSize } from '@vueuse/core'
import { PhMagnifyingGlass, PhX } from '@phosphor-icons/vue'
import MainNavPerson from '@/layouts/components/MainNavPerson.vue'
import BDropdown from '@/components/BDropdown.vue'
import { formatDate } from '@/functions/utils'
import ENV from '@/constants/env'
import useSearch from '@/composables/search'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import type { Props as DropdownProps } from '@/types/components/BDropdown'

const { query, reset, searching, showResults, people, tags, events } = useSearch()
const fileHost = ENV.fileHost

const focused = ref(false)
const searchDropdown = ref(null)
const { width } = useElementSize(searchDropdown)

const open = computed(() => query.value !== `` || focused.value || showResults.value)

const wrapperClasses = cva([`ml-auto`, `transition-all`], {
    variants: {
        open: {
            true: [`w-[300px]`],
            false: [`w-9`]
        }
    }
})

const dropdownProps = computed((): DropdownProps & Record<string, unknown> => ({
    triggers: [],
    popperTriggers: [],
    distance: 14,
    placement: `bottom-end`,
    popperClass: `horizon-ui`,
    class: [`relative`, `overflow-hidden`, `h-9`, `w-full`, `rounded-full`, `transition-all`],
    shown: query.value.length > 1,
    autoHide: false,
    strategy: `fixed`
}))

defineExpose({ width })
</script>

<template>
    <div
        id="search-wrapper"
        ref="searchDropdown"
        :class="wrapperClasses({ open })">
        <BDropdown v-bind="dropdownProps">
            <Transition
                enter-active-class="transition-all"
                leave-active-class="transition-all"
                enter-from-class="scale-75 opacity-0"
                leave-to-class="scale-75 opacity-0">
                <button
                    v-if="query.length"
                    class="absolute left-0 top-0 flex h-9 w-9 items-center justify-center"
                    @click="reset">
                    <PhX class="size-6 text-primary-900" />
                </button>
                <div
                    v-else
                    class="pointer-events-none absolute left-0 top-0 flex h-9 w-9 items-center justify-center">
                    <PhMagnifyingGlass class="size-6 text-primary-900" />
                </div>
            </Transition>

            <input
                v-model="query"
                type="search"
                placeholder="Search People, Tags and Events"
                class="!mb-0 box-border !h-full !w-full rounded-full !border-0 !bg-primary-50 !py-0 !pl-10 !pr-4 text-base/none text-secondary-600 outline-none placeholder:text-secondary-600"
                @mouseenter="focused = true"
                @mouseleave="focused = false"
                @focus="focused = true"
                @blur="focused = false" />

            <template #content>
                <div class="min-h-[2rem] w-[300px]">
                    <div
                        v-if="searching"
                        class="flex items-center bg-primary-50/30 !p-4 text-sm font-medium text-primary">
                        <LoadingSpinner class="!mr-2 h-4" /> Searching
                    </div>
                    <template v-else>
                        <ul v-if="people.length || tags.length || events.length">
                            <li
                                v-for="{ avatar, id, name } in people"
                                :key="id">
                                <MainNavPerson
                                    :id="id"
                                    :avatar="avatar"
                                    :name="name" />
                            </li>
                            <li
                                v-for="{ id, name } in tags"
                                :key="id">
                                <a
                                    :href="`/tags/view/${id}`"
                                    class="flex items-center !p-4 hover:bg-secondary-50 hover:no-underline focus:bg-neutral-100 focus:outline-none">
                                    <div class="!mr-4">
                                        <img
                                            :src="`${fileHost}/img/search/tag.jpg`"
                                            class="w-[55px]" />
                                    </div>
                                    <div>
                                        <span class="text-sm font-medium text-primary">
                                            {{ name }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li
                                v-for="{ id, name, date } in events"
                                :key="id">
                                <a
                                    :href="`/events/view/${id}`"
                                    class="flex items-center !p-4 hover:bg-secondary-50 hover:no-underline focus:bg-neutral-100 focus:outline-none">
                                    <div class="!mr-4">
                                        <img
                                            :src="`${fileHost}/img/search/calendar.jpg`"
                                            class="w-[55px]" />
                                    </div>
                                    <div class="flex flex-col">
                                        <span class="text-sm font-medium text-primary">
                                            {{ name }}
                                        </span>
                                        <span class="text-xs">
                                            {{ formatDate(date) }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div
                            v-else
                            class="bg-primary-50/30 !py-4 !pl-4 !pr-6 text-sm font-medium text-primary">
                            There are no results matching your search.
                        </div>
                    </template>
                </div>
            </template>
        </BDropdown>
    </div>
</template>
