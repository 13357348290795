export default [
    {
        path: `/roles/settings`,
        component: () => import('@/pages/admin/RoleSettings.vue'),
        meta: {
            isVue: true,
            sideNav: `admin`,
            feature: `vue-role-settings`,
            isLegacyRoute: true
        }
    }
]
