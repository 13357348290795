<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Dropdown as VDropdown } from 'floating-vue'
import { useToast, TYPE } from 'vue-toastification'
import { find } from 'lodash'
import { type Constructor, useModal } from 'vue-final-modal'
import { cva } from 'class-variance-authority'
import useNavSettings from '@/composables/nav/settings'
import { useMe } from '@/queries/me'
import useApi from '@/composables/api'
import useUtils from '@/store/utils'
import MainNavIconLink from '@/layouts/components/MainNavIconLink.vue'
import UserSettings from '@/components/modals/UserSettings.vue'
import UserPassword from '@/components/modals/UserPassword.vue'
import UserNotifications from '@/components/modals/UserNotifications.vue'
import UserReferral from '@/components/modals/UserReferral.vue'
import useExtensions from '@/queries/extensions'
import ExtensionInstaller from '@/components/extensions/ExtensionInstaller.vue'
import type IntegrationResource from '@/services/api/transformers/IntegrationResource'
import { PhGear, PhLifebuoy, PhSignOut, PhX } from '@phosphor-icons/vue'

const utils = useUtils()
const { logout } = useApi()
const { userNav, orgNav } = useNavSettings()
const { data: me } = useMe()
const toast = useToast()

const extraDropdown = ref(null)

const firstName = computed(() => me.value?.firstName ?? ``)
const lastName = computed(() => me.value?.lastName ?? ``)
const orgStatus = computed(() => me.value?.organization.status ?? 0)
const orgName = computed(() => me.value?.organization.name ?? ``)

const dropdownProps = computed(() => ({
    triggers: [`click`],
    placement: `bottom-end`,
    distance: 14,
    skidding: 4,
    theme: `main-nav-settings`,
    popperClass: `horizon-ui`,
    autoHide: true,
    positioningDisabled: utils.getMaxWidth(`md`),
    strategy: `fixed`
}))

const userSettingsModal = useModal({ component: UserSettings as Constructor })
const userPasswordModal = useModal({ component: UserPassword as Constructor })
const userNotificationsModal = useModal({ component: UserNotifications as Constructor })
const userReferralModal = useModal({ component: UserReferral as Constructor })

const extensionInstallModal = ref<InstanceType<typeof ExtensionInstaller>>()

const { data: extensions } = useExtensions()
const servicePlan = computed<IntegrationResource | undefined>(() =>
    find(extensions.value, { name: 'service_plan_integration' })
)

const currentPath = computed(() => window.location.pathname)

const contentClasses = cva(
    [`fixed`, `inset-0`, `grid auto-rows-auto`, `overflow-y-auto`, `bg-white`, `lg:static`, `lg:inset-auto`],
    {
        variants: {
            org: {
                true: [`lg:grid-cols-2`],
                false: [`lg:grid-cols-1`]
            }
        }
    }
)

const action = (hide: () => void, action?: string) => {
    hide()

    if (action) {
        switch (action) {
            case `songLibrary`:
                if (servicePlan.value) {
                    extensionInstallModal.value?.open(servicePlan.value)
                } else {
                    toast(`Extension not found`, { type: TYPE.ERROR })
                }
                break
            case `launchUserSettings`:
                userSettingsModal.open()
                break
            case `launchChangePassword`:
                userPasswordModal.open()
                break
            case `launchNotifications`:
                userNotificationsModal.open()
                break
            case `launchReferral`:
                userReferralModal.open()
                break
        }
    }
}
</script>

<template>
    <div
        ref="extraDropdown"
        class="!ml-4">
        <VDropdown v-bind="dropdownProps">
            <button
                type="button"
                class="flex size-9 items-center justify-center rounded-full bg-primary-50 !p-0 transition duration-200 ease-in-out hover:scale-[1.2] hover:opacity-80">
                <PhGear class="size-6 text-primary-900" />
            </button>

            <template #popper="{ hide }">
                <div :class="contentClasses({ org: Boolean(orgNav.length) })">
                    <div
                        v-if="orgNav.length"
                        class="lg:w-[28rem]">
                        <div class="flex items-center justify-between gap-4 !p-3 md:!px-6 md:!py-4">
                            <div class="flex flex-col gap-1">
                                <h3 class="text-lg font-bold">Manage your organization</h3>
                                <p class="text-xs">{{ orgName }}</p>
                            </div>

                            <button
                                type="button"
                                class="flex size-8 items-center justify-center lg:hidden"
                                @click="hide">
                                <PhX class="size-4" />
                            </button>
                        </div>

                        <ul class="grid grid-cols-2 !pb-3 md:!pb-6 lg:grid-cols-1">
                            <li
                                v-for="(link, index) in orgNav"
                                :key="`OrgNav${index}`">
                                <MainNavIconLink
                                    v-bind="link"
                                    @action="action(hide, $event)" />
                            </li>
                        </ul>
                    </div>

                    <div class="border-t border-solid lg:w-[28rem] lg:border-l lg:border-t-0">
                        <div class="flex items-center justify-between gap-4 !p-3 md:!px-6 md:!py-4">
                            <div class="flex flex-col gap-1">
                                <h3 class="text-lg font-bold">Manage your user account</h3>
                                <p class="text-xs">{{ firstName }} {{ lastName }}</p>
                            </div>

                            <button
                                v-if="!orgNav.length"
                                type="button"
                                class="flex size-8 items-center justify-center lg:hidden"
                                @click="hide">
                                <PhX class="size-4" />
                            </button>
                        </div>

                        <ul class="grid grid-cols-2 !pb-3 md:!pb-6 lg:grid-cols-1">
                            <li
                                v-for="(link, index) in userNav"
                                :key="`OrgNav${index}`">
                                <MainNavIconLink
                                    v-bind="link"
                                    @action="action(hide, $event)" />
                            </li>
                        </ul>

                        <div class="flex items-center gap-4 !p-3 lg:!p-4">
                            <a
                                :href="
                                    orgStatus === 0
                                        ? `https://www.breezechms.com/meetings/breezesales/get-help`
                                        : `https://support.breezechms.com`
                                "
                                class="flex items-center gap-2 text-sm font-semibold text-primary hover:no-underline"
                                @click="hide">
                                <PhLifebuoy class="size-5" />
                                Get Help
                            </a>
                            <a
                                v-if="orgStatus > 0"
                                href="/login/logout"
                                class="flex items-center gap-2 text-sm font-semibold text-primary hover:no-underline"
                                @click.prevent="logout(currentPath)">
                                <PhSignOut class="size-5" />
                                Log Out
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </VDropdown>
    </div>
    <ExtensionInstaller ref="extensionInstallModal" />
</template>

<style lang="scss">
.v-popper {
    &--some-open--main-nav-settings {
        @apply overflow-hidden lg:overflow-auto;
    }

    &--theme-main-nav-settings {
        &.v-popper__popper {
            @apply fixed;
        }

        .v-popper {
            &__wrapper {
                @apply overflow-hidden bg-white lg:rounded-lg lg:shadow-dropdown;
            }

            &__arrow-container {
                @apply hidden;
            }
        }
    }
}
</style>
