import { useMutation, useQuery } from '@tanstack/vue-query'
import { plainToInstance } from 'class-transformer'
import { computed, type Ref } from 'vue'
import { QUERY_KEYS } from '@/constants/query'
import useApi from '@/composables/api'
import PersonDetailResource from '@/services/api/transformers/PersonDetailResource'
import { TIME } from '@/constants/time'
import type { PersonUpdateRequest } from '@/services/api/client'
import queryClient from '@/plugins/query'

const usePerson = (id: Ref, enabled?: Ref, include?: Ref) => {
    const { api } = useApi()

    return useQuery({
        queryKey: computed(() => QUERY_KEYS.person(id.value, include?.value)),
        queryFn: () => api.people.getOnePerson({ id: id.value, include: include?.value }).then(({ data }) => data),
        select: data => plainToInstance(PersonDetailResource, data),
        staleTime: TIME.Minute(5),
        enabled: computed(() => (enabled ? enabled.value ?? true : true))
    })
}

const useUpdatePerson = (id: number, include?: string) => {
    const { api } = useApi()

    return useMutation({
        mutationFn: (requestBody: PersonUpdateRequest) => api.people.updatePerson({ id, requestBody }),
        onSuccess: ({ data }) => {
            queryClient.setQueryData(QUERY_KEYS.person(id, include || ``), data)
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.groups.details() })
        }
    })
}

export { usePerson, useUpdatePerson }
