import { Expose, instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import type { MyOrganization as Model } from '@/services/api/client'
import type { CamelCaseKeys, TransformableDate } from '@/types/utility'
import { transformDate } from '@/functions/utils'
import MyCountry from '@/services/api/transformers/MyCountry'

export type SerializedMyOrganization = Model

export default class MyOrganization implements CamelCaseKeys<SerializedMyOrganization> {
    id!: SerializedMyOrganization['id']

    name!: SerializedMyOrganization['name']

    subdomain!: SerializedMyOrganization['subdomain']

    language!: SerializedMyOrganization['language']

    status!: SerializedMyOrganization['status']

    @Expose({ name: 'created_on' })
    @Transform(({ value }) => transformDate(value))
    createdOn!: TransformableDate<SerializedMyOrganization['created_on']>

    recentGiving!: SerializedMyOrganization['recentGiving']

    @Transform(({ value }) => plainToInstance(MyCountry, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    country!: MyCountry

    @Expose({ name: 'tithely_billing' })
    tithelyBilling!: SerializedMyOrganization['tithely_billing']

    @Expose({ name: 'tithely_location_id' })
    tithelyLocationId!: SerializedMyOrganization['tithely_location_id']

    @Expose({ name: 'tithely_organization_id' })
    tithelyOrganizationId!: SerializedMyOrganization['tithely_organization_id']

    @Expose({ name: 'tithely_giving' })
    tithelyGiving!: SerializedMyOrganization['tithely_giving']

    @Expose({ name: 'onboarding_steps' })
    onboardingSteps!: SerializedMyOrganization['onboarding_steps']

    constructor(values: MyOrganization) {
        Object.assign(this, values)
    }
}
