import type { Ref } from 'vue'
import type { Nullable } from '@/types/utility'
import queryKeys from '@/constants/queryKeys'

const CACHE_KEY = `breeze:queryCache`

const QUERY_KEYS = {
    me: [`me`],
    user: [`user`],
    userNotifications: () => [...QUERY_KEYS.user, `notifications`],
    loginConfig: (subdomain: string) => [`loginConfig`, subdomain],
    account: [`account`],
    accountTithelyLink: () => [...QUERY_KEYS.account, `tithelyLink`],
    accountTithelySubscription: () => [...QUERY_KEYS.account, `tithelySubscription`],
    accountBreezeSubscription: () => [...QUERY_KEYS.account, `breezeSubscription`],
    accountTithelyBundle: () => [...QUERY_KEYS.account, `tithelyBundle`],
    apiApplication: (uuid: string) => [`apiApplication`, uuid],
    eventInstances: [`eventInstances`],
    eventInstance: (id: number) => [...QUERY_KEYS.eventInstances, id],
    extensions: [`extensions`],
    extension: (id: Ref<number>) => [...QUERY_KEYS.extensions, id],
    history: (type?: string) => (type ? [`history`, type] : [`history`]),
    people: [`people`],
    peopleSearch: (filters: Ref<string>, page?: Ref<number>) => [...QUERY_KEYS.people, `search`, filters, page],
    peopleSavedSearches: () => [...QUERY_KEYS.people, `savedSearches`],
    peopleProfileFields: () => [...QUERY_KEYS.people, `profileFields`],
    person: (id: number, include: string) => [...QUERY_KEYS.people, [id, include]],
    servicePlans: [`servicePlans`],
    servicePlan: (id: Nullable<number>) => [...QUERY_KEYS.servicePlans, id],
    servicePlanTemplates: [`servicePlanTemplates`],
    savedSearches: [`savedSearches`],
    savedSearch: (id: number) => [...QUERY_KEYS.savedSearches, id],
    messages: [`messages`],
    messagesDetail: (id?: Ref<string | number>) => [...QUERY_KEYS.song, id],
    messageTemplates: [`messageTemplates`],
    messageTemplatesType: (type: Nullable<string>) => [...QUERY_KEYS.messageTemplates, type],
    attachments: [`attachments`],
    attachmentInstance: (id: number) => [...QUERY_KEYS.attachments, id],
    song: [`song`],
    songDetail: (id: number) => [...QUERY_KEYS.song, id],
    songs: (page?: Ref<number>, filters?: Ref) => (page ? [`songs`, page, filters] : [`songs`]),
    songFilters: (filter: string) => [`songFilters`, filter],
    songSearch: (search: Ref<string>, page?: Ref<number>) =>
        page ? [...QUERY_KEYS.songs(page), search] : [...QUERY_KEYS.songs(), search],
    songVersion: [`songVersion`],
    songVersions: (versionId: number) => [...QUERY_KEYS.songVersion, versionId],
    songCombinedSearch: (searchTerm: Ref<string>, source?: string) => [`songSearch`, searchTerm, `source`, source],
    songTags: [`songTags`],
    songTagsSearch: (search: Ref<string>) => [...QUERY_KEYS.songTags, `songTags`, search],
    texts: [`texts`],
    text: (id: number) => [...QUERY_KEYS.texts, id],
    ccliSongs: [`ccliSongs`],
    ccliSong: (id: Ref<string>) => [...QUERY_KEYS.ccliSongs, id],
    exportSongTemplate: [`exportSongTemplate`],
    ...queryKeys
}

export { CACHE_KEY, QUERY_KEYS }
