import {
    PhBell,
    PhChecks,
    PhClockCounterClockwise,
    PhGear,
    PhGift,
    PhRobot,
    PhLockSimpleOpen,
    PhMusicNotesPlus,
    PhUser,
    PhUserList,
    PhUsers
} from '@phosphor-icons/vue'
import type { NavSettingsSection, NavSettingsItem } from '@/types/nav/settings'

const NAV_SETTINGS: Record<NavSettingsSection, NavSettingsItem[]> = {
    organization: [
        {
            label: `Bulk Tasks`,
            description: `Manage Breeze data by bulk importing and deleting.`,
            icon: PhUserList,
            route: `/bulk/merge_people`,
            roles: [`bulk`]
        },
        {
            label: `Users & Roles`,
            description: `Manage what users can see when they log into Breeze.`,
            icon: PhUsers,
            route: `/admin/users`,
            roles: [`users`]
        },
        {
            label: `Automations`,
            description: `Create smart tags, schedule birthday emails, & more!`,
            icon: PhRobot,
            route: `/bulk/automated_tasks`,
            roles: [`automations`]
        },
        {
            label: `Profile Fields`,
            description: `Customize information stored on People profiles.`,
            icon: PhChecks,
            route: `/admin/profile`,
            roles: [`profile`]
        },
        {
            label: `Manage Account`,
            description: `Keep account and payment settings up to date.`,
            icon: PhGear,
            route: `/account`,
            roles: [
                `account_settings`,
                `account_payment_settings`,
                `account_invoices`,
                `account_export`,
                `account_restore`,
                `account_log`
            ],
            orRoles: true
        },
        {
            label: `Song Library`,
            description: `Add and store songs here for your setlists.`,
            icon: PhMusicNotesPlus,
            route: `/songs`,
            features: [`song-library`],
            roles: [`events_modify`]
        }
    ],
    user: [
        {
            label: `User Settings`,
            description: `Update your name and email credentials.`,
            icon: PhUser,
            action: `launchUserSettings`,
            roles: [`!settings_password`]
        },
        {
            label: `Change Password`,
            description: `Update the password for your Breeze login.`,
            icon: PhLockSimpleOpen,
            action: `launchChangePassword`,
            roles: [`!settings_password`]
        },
        {
            label: `Notifications`,
            description: `Set email alerts for activity within your church.`,
            icon: PhBell,
            action: `launchNotifications`
        },
        {
            label: `History`,
            description: `View past activity in People, Tags, and Events.`,
            icon: PhClockCounterClockwise,
            route: `/history`,
            roles: [`history`]
        },
        {
            label: `Refer & Receive $200`,
            description: `Share your referral link to get a $200 Amazon gift card.`,
            icon: PhGift,
            action: `launchReferral`
        }
    ]
}

export default NAV_SETTINGS
