/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Update Group Request
 */
export type UpdateGroupRequest = {
    /**
     * The name of the group
     */
    name?: string;
    /**
     * The description of the group
     */
    description?: string;
    /**
     * The venue of the group
     */
    venue?: string | null;
    /**
     * The frequency of the group
     */
    frequency?: string | null;
    /**
     * The public status of the group
     */
    public?: boolean;
    /**
     * The mds group id of the group
     */
    mds_group_id?: string | null;
    /**
     * The in app status of the group
     */
    in_app?: boolean;
    /**
     * The ids of the files to attach to the group.
     */
    file_ids?: Array<number>;
    /**
     * The id of the file to attach to the group.
     */
    avatar_file_id?: number | null;
    /**
     * The group has chat enabled
     */
    chat_enabled?: boolean;
    /**
     * The group's status
     */
    status?: UpdateGroupRequest.status;
};

export namespace UpdateGroupRequest {

    /**
     * The group's status
     */
    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
    }


}

