<script lang="ts" setup>
import { useMe } from '@/queries/me'
import SupportToolbar from '@/layouts/components/SupportToolbar.vue'
import MainNav from '@/layouts/components/MainNav.vue'
import SideNav from '@/layouts/components/SideNav.vue'
import type { SideNavLocation } from '@/types/sideNav'
import useEcho from '@/composables/echo'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { includes, some, startsWith } from 'lodash'
import { PUBLIC_ROUTES } from '@/constants/routes'
import { useTithely } from '@/queries/tithely'
import { defineAsyncComponent } from 'vue'

interface Props {
    feature?: string
    hasFeature?: boolean
    isVue?: boolean
    sideNav?: SideNavLocation
}

defineProps<Props>()

useEcho()
const { status, data, isFetching } = useMe()
const route = useRoute()
const { billing, syncTithelyBilling } = useTithely()

// Status/Permissions checks
watch(
    data,
    newData => {
        if (
            !isFetching.value && // Wait for any refreshes to complete
            status.value === `success` &&
            newData &&
            includes(newData.features, `vue-login`) &&
            !some(PUBLIC_ROUTES, path => startsWith(route.path, path))
        ) {
            switch (true) {
                case newData.organization.status === 2 && route.path !== `/r/suspended`:
                    window.open(`/r/suspended`, `_self`)
                    break
                case newData.organization.status === 4 && route.path !== `/disabled`:
                    window.open(`/disabled`, `_self`)
                    break
                case includes(newData.roles ?? [], `checkin`):
                    window.open(`/checkin/v3`, `_self`)
                    break
                case newData.organization.status < 2 && includes([`/r/suspended`, `disabled`], route.path):
                    window.open(`/r/dashboard`, `_self`)
                    break
            }
        }
    },
    {
        immediate: true
    }
)

watch(
    [data, billing],
    ([newData, newBilling]) => {
        //Check for all access customer and service plan integration
        if (newData && newBilling?.allAccessCustomer && !includes(newData.features, `service_plan_integration`)) {
            //If not enabled sync billing and enable the extension through the breeze backend
            syncTithelyBilling()
        }
    },
    {
        immediate: true
    }
)

let showInviteUserModal = false

const InviteUserModal = defineAsyncComponent(() =>
    import('../components/modals/InviteUserModal.vue')
)

// Show the invite user modal if the user has not completed the invite users onboarding step
watch(
    data,
    newData => {
        if (
            newData &&
            includes(newData.organization.onboardingSteps, 'invite_users')
        ) {
            showInviteUserModal = true
        }
    },
    {
        immediate: true
    }
)
</script>

<template>
    <SupportToolbar />

    <template v-if="status === `success`">
        <MainNav />

        <div v-if="feature && !hasFeature" />

        <div
            v-else-if="isVue"
            class="flex h-[calc(100vh-76px)] flex-col items-stretch lg:flex-row">
            <SideNav
                v-if="sideNav"
                :menu="sideNav" />

            <slot />
        </div>
        <slot v-else />
    </template>

    <InviteUserModal
        v-if="showInviteUserModal"
        ref="inviteUserModal"
        :me="data"/>
</template>

<style scoped>
:slotted(div) {
    @apply z-[1] grow overflow-y-auto print:overflow-y-visible;
}
</style>
