import { computed, ref, watch, type Ref } from 'vue'
import { every } from 'lodash'
import { hasMinLength, hasUpperAndLower, hasNumber, hasSymbol, checkSafePassword } from '@/utils/password'

export interface PasswordRequirement {
    label: string
    passed: boolean
}

export const usePasswordRequirements = (password: Ref<string>) => {
    const isPasswordSafe = ref(true)

    watch(password, async newPassword => {
        if (newPassword) {
            isPasswordSafe.value = await checkSafePassword(newPassword)
        } else {
            isPasswordSafe.value = true
        }
    })

    const passwordRequirements = computed<PasswordRequirement[]>(() => {
        const pwd = password.value || ''
        return [
            {
                label: 'Password must be at least 8 characters',
                passed: hasMinLength(pwd)
            },
            {
                label: 'Password must contain at least 1 upper and 1 lower case letter',
                passed: hasUpperAndLower(pwd)
            },
            {
                label: 'Password must contain at least 1 number',
                passed: hasNumber(pwd)
            },
            {
                label: 'Password must contain at least 1 symbol',
                passed: hasSymbol(pwd)
            },
            {
                label: 'Password must be safe (not compromised)',
                passed: pwd.length > 0 && isPasswordSafe.value
            }
        ]
    })

    const allRequirementsPassed = computed(() => {
        return every(passwordRequirements.value, requirement => requirement.passed)
    })

    return {
        passwordRequirements,
        allRequirementsPassed
    }
}
