import { Expose } from 'class-transformer'
import type { PeopleDetailProfileFieldResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPeopleDetailProfileFieldResource = Model

export default class PeopleDetailProfileFieldResource
    implements CamelCaseKeys<SerializedPeopleDetailProfileFieldResource>
{
    id?: Model['id']

    field?: Model['field']

    type?: Model['type']

    @Expose({ name: 'option_id' })
    optionId?: Model['option_id']

    @Expose({ name: 'profile_section_id' })
    profileSectionId?: Model['profile_section_id']

    value?: Model['value']

    constructor(values: PeopleDetailProfileFieldResource) {
        Object.assign(this, values)
    }
}
