<script lang="ts" setup>
import { SpBadge } from '@tithely/sproutkit-vue'
import type { NavSettingsItem } from '@/types/nav/settings'

const props = defineProps<NavSettingsItem>()

const emit = defineEmits<{
    action: [string]
}>()

const action = () => {
    if (props.action) {
        emit(`action`, props.action)
    } else if (props.route) {
        window.open(props.route, `_self`)
    }
}
</script>

<template>
    <a
        :href="props.route || `#`"
        class="group flex items-center gap-3 !p-3 hover:bg-primary-100 hover:no-underline md:gap-4 md:!px-6"
        @click.prevent="action">
        <div
            class="flex size-8 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-100 group-hover:bg-primary-200 lg:size-10">
            <Component
                :is="props.icon"
                class="size-4 lg:size-6" />
        </div>

        <div>
            <h4 class="mb-2 flex items-center gap-2 text-sm font-semibold text-primary">
                {{ props.label }}

                <SpBadge
                    v-if="props.badge"
                    size="sm"
                    :intent="props.badge === `New` ? `warning` : `info`">
                    {{ props.badge }}
                </SpBadge>
            </h4>

            <p class="text-2xs sm:text-xs">
                {{ props.description }}
                <span
                    v-if="props.tag"
                    class="text-blue-600 underline hover:text-blue-800">
                    {{ props.tag }}
                </span>
            </p>
        </div>
    </a>
</template>
