/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationChange } from '../models/NotificationChange';
import type { NotificationResource } from '../models/NotificationResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Notification Settings
     * @returns any OK
     * @throws ApiError
     */
    public getNotificationSettings(): CancelablePromise<{
        data: Array<NotificationResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public enableNotification({
        requestBody,
    }: {
        requestBody?: NotificationChange,
    }): CancelablePromise<{
        data: Array<NotificationResource>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * This endpoint will add, update, and delete notifications settings for the user.
     * It will only change the setting for the notifications in the array.
     * In other words, you don't have to send all notification settings, just those that changed.
     * @returns any OK
     * @throws ApiError
     */
    public enableMultipleNotifications({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Array of notifications to change.
             */
            notifications?: Array<NotificationChange>;
        },
    }): CancelablePromise<{
        data: Array<NotificationResource>;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public disableNotification({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notifications/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
