import type { PersonPhoneResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPersonPhoneResource = Model

export default class PersonPhoneResource implements CamelCaseKeys<SerializedPersonPhoneResource> {
    number?: Model['number']

    private?: Model['private']

    doNotText?: Model['doNotText']

    constructor(values: PersonPhoneResource) {
        Object.assign(this, values)
    }
}
