export default {
    createModal: {
        newGroup: `New Group`,
        photo: `Photo`,
        upload: `Upload`,
        name: `Name`,
        namePlaceholder: `Enter group name`,
        status: `Status`,
        description: `Description`,
        showInApp: `Show in app`,
        frequency: `Frequency`,
        location: `Location`,
        enableChat: `Enable chat`,
        makePublic: `Make public`,
        createFromExistingTags: `Create from existing tags`,
        addGroup: `Create group`,
        addGroupWithMembers: `Create group with %{memberCount} members`,
        groupCreated: `%{name} group created!`,
        numberPeopleAdded: `%{number} people added`,
        goToGroupPage: `Go to group page`,
        goToDashboard: `Go to dashboard`,
        nameMustBe: `Name must be at least 3 characters long`,
        tagsInputLabel: `Add members from tags`,
        descriptionMustBe: `Description must be at less than 400 characters long`,
        frequencyRequiredWithShowInApp: `Frequency is required when "Show in app" is enabled.`,
        locationRequiredWithShowInApp: `Location is required when "Show in app" is enabled.`
    },
    membersModal: {
        addMembersError: `Member could not be added. Please try again later. | Members could not be added. Please try again later.`,
        addMembersOrTags: `Add members individually or via tags`,
        addMembersSuccess: `Member added successfully! | Members added successfully!`,
        addSelected: `Add selected`,
        alreadyInGroup: `Already in the group`,
        nameNotFound: `Uh oh! Looks like we don’t have any results for that name`,
        searchMembers: `Search members`,
        searchTags: `Search tags`,
        unsavedChangesWarning: `You have unsaved members. Are you sure you want to exit?`
    },
    generalPage: {
        saveChanges: `Save changes`,
        deleteGroup: `Delete group`
    }
}
